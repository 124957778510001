import * as React from 'react';
import styled from 'styled-components'
import { ContentContainer } from './ContentContainer';
import { Icons } from '..';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';

interface IFeaturedDataProps {
	featuredImage: {
		node: {
			sourceUrl: string
		}
	};
	title: string;
	uri: string;
	excerpt: string;
}

interface IFeaturedPost {
	postType?: string
}

const ImageWrapper = styled.div<{ backgroundImageUrl: string }>`
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: url('${props => props.backgroundImageUrl}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-blend-mode:multiply;
    min-height: 200px;
    @media (min-width: ${props => props.theme.screensizes.medium}) {
        min-height: 395px;
    }
`

const StyledFeaturedPost = styled.div<{ backgroundImageUrl: string }>`
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    &:before {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        background: url('${props => props.backgroundImageUrl}'), ${props => props.theme.colors.nearlyNavy};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-blend-mode:multiply;
        filter: blur(10px);
        transform: scale(1.2, 1.2);
    }
    .content-container {
        position: relative;
        margin: 0 auto;
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            padding-top: 3rem !important;
            padding-bottom: 3rem !important;
            margin: 0 auto;
        } ]
    }
    .col-wrapper {
        @media (max-width: ${props => props.theme.screensizes.medium}) {
            flex-direction: column-reverse !important;
        }
    }

    h3.post-title {
        color: var(--meaningfulMarigold);
        text-transform:uppercase;
        margin-bottom:20px;
        margin-top:20px;
    }

    .summary {
        font-weight: 300;

        .more-link {
            display: none;
        }
    }

    p {
        color: var(--mobyDickWhite);

        &.content-intro {
            font-size:20px;
            line-height:25px;
            @media (min-width: ${props => props.theme.screensizes.medium}) {
                font-size:24px;
                line-height:31px;
            }
        }
    }

    .read-more {
        color: var(--mobyDickWhite);
        font-family: var(--din-font);
    }
`;

const FeaturedPost: React.FC<IFeaturedPost> = ({ postType }) => {
    const [featured, setFeaturedData] = React.useState<IFeaturedDataProps[]>(new Array<IFeaturedDataProps>())
	const { featuredPosts } = useStaticQuery(graphql`
        query {
            posts: wordpress {
                themeGeneralSettings {
                    singleFeaturedPost {
                        featuredPosts {
                            postPluralTitle
                            post {
                                ... on WpGql_Post {
                                    id
                                    title
                                    excerpt
                                    uri
                                    featuredImage {
                                        node {
                                            sourceUrl
                                        }
                                    }
                                }
                                ... on WpGql_Whitepaper {
                                    id
                                    title
                                    excerpt
                                    uri
                                    featuredImage {
                                        node {
                                            sourceUrl
                                        }
                                    }
                                }
                                ... on WpGql_Brochure {
                                    id
                                    title
                                    excerpt
                                    uri
                                    featuredImage {
                                        node {
                                            sourceUrl
                                        }
                                    }
                                }
                                ... on WpGql_CaseStudy {
                                    id
                                    title
                                    excerpt
                                    uri
                                    featuredImage {
                                        node {
                                            sourceUrl
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)?.posts?.themeGeneralSettings?.singleFeaturedPost

    React.useMemo(() => {
        if (!postType) return;
		setFeaturedData(
			(
				featuredPosts?.filter(post => (
					post?.postPluralTitle.toLowerCase().includes(postType?.toLowerCase())
				)).map(i => (
					i.post[0]
				))
			)
		)
    }, [postType]);

	return (
		<StyledFeaturedPost backgroundImageUrl={featured[0]?.featuredImage?.node?.sourceUrl || ''}>
			<ContentContainer>
				<div className="featured-post">
					<div className="col-wrapper">
						<div className="col-40">
							<h3 className="post-title">{postType !== "caseStudies" ? postType : "Case Studies"}</h3>
							<p className="content-intro">{featured[0]?.title}</p>
							<div className="summary" dangerouslySetInnerHTML={{ __html: featured[0]?.excerpt }}></div>
							<a href={featured[0]?.uri} className='with-icon read-more'>Read More <Icons.Play width={'20px'} /></a>
						</div>
						<div className="col-60">
							<ImageWrapper className="img-wrapper" backgroundImageUrl={featured[0]?.featuredImage?.node?.sourceUrl || ''} />
						</div>
					</div>
				</div>
			</ContentContainer>
		</StyledFeaturedPost>
	)
}

export default FeaturedPost