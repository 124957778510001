import * as React from 'react'
import styled from 'styled-components'
import { HeroBanner } from '@/src/Components/Common/HeroBanner'
import FeaturedPost from '@/src/Components/Common/FeaturedPost'
import PostGrid from '@/src/Components/Common/Posts/PostGrid'

interface INewsPage {
    postType?:any
}

const NewsPage: React.FC<INewsPage> = (props) => {
 
    const [postType, setPostType] = React.useState('News');
    return (
        <>
            <HeroBanner  pageSlug="knowledge" hasTabs={true} />
            <FeaturedPost postType={postType} />
            <PostGrid 
            sectionTitle="Featured Articles" 
            postType={{
                type:postType,
                title: 'News'
            }} 
            hasPagination={true} 
            hasViewAll={false} />
        </>
    )
}

export default NewsPage;